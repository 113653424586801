//place custom scripts for admin
function increaseChildVal() {
    var childNumber = $('#child_number');
    var oldValue = childNumber.val();
    console.log($.isNumeric(oldValue));
    var newVal = parseFloat(oldValue) + 1;
    childNumber.val(newVal);
}

function decreaseChildVal() {
    var childNumber = $('#child_number');
    var oldValue = childNumber.val();
    console.log($.isNumeric(oldValue));
    if (oldValue > 0) {
        var newVal = parseFloat(oldValue) - 1;
    } else {
        newVal = 0;
    }
    childNumber.val(newVal);
}

$('.toggle-table-col').click(function () {
    let tableId = $(this).data('table-id');
    console.log(tableId);
    console.log($(this).is(':checked'), $('#th-' + tableId).removeClass('d-none'));
    if (!$(this).is(':checked')) {
        $('.th-' + tableId).addClass('d-none');
        $('.td-' + tableId).addClass('d-none');
    } else {
        $('.th-' + tableId).removeClass('d-none');
        $('.td-' + tableId).removeClass('d-none');
    }
})

//Table sorting
$('.sortable-th').click(function () {
    $('.highlighted-th').removeClass('highlighted-th');
    $('.sort-icon').remove();
    var table = $(this).parents('table').eq(0)
    var rows = table.find('tr:gt(0)').toArray().sort(comparer($(this).index()))
    this.asc = !this.asc
    if (!this.asc) {
        rows = rows.reverse();
        $(this).append('<i class="sort-icon fas fa-sort-down"></i>');
    } else {
        $(this).append('<i class="sort-icon fas fa-sort-up"></i>');
    }
    for (var i = 0; i < rows.length; i++) {
        table.append(rows[i])
    }
})

function comparer(index) {
    return function (a, b) {
        var valA = getCellValue(a, index), valB = getCellValue(b, index);
        return $.isNumeric(valA) && $.isNumeric(valB) ? valA - valB : valA.toString().localeCompare(valB)
    }
}

function getCellValue(row, index) {
    if ($(row).children('td').eq(index).data('date')) {
        return $(row).children('td').eq(index).data('date')
    } else if ($(row).children('td').eq(index).attr('date')) {
        return $(row).children('td').eq(index).attr('date')
    } else {
        return $(row).children('td').eq(index).text()
    }
}

//prevent onclick event for table buttons
$('.prevent-onclick').click(function(e) {
    e.stopPropagation();
});

//disable info forms if present
$(document).ready(function () {
    if ($("#payment-info-form").length != 0) {
        $("#payment-info-form input, textarea, select").prop("disabled", true);
    }

    if ($("#payment-request-info-form").length != 0) {
        $('#admin-edit-anchor')[0].click();
        $("#payment-request-info-form input, textarea, select").prop("disabled", true);
    }
    if($('#revocation_checker').length > 0){
        $("#revocation_checker").prop("disabled", false);
        $("#revocation_comment").prop("disabled", false);
    }

    $(".currencyNumberField").change(function() {
        $(this).val(parseFloat($(this).val()).toFixed(2));
    });

    $("#eco_data_iban").on('input',function(e) {
        var characters = $(this).val().replaceAll(' ', '').split('');
        var newVal = '';
        for(i = 0; i< characters.length; i++){
            if(i > 0 && (i % 4) === 0){
                newVal += ' ';
            }
            newVal += characters[i];
        }
        $(this).val(newVal);
    });
    $("#eco_data_iban_authorized_representative").on('input',function(e) {
        var characters = $(this).val().replaceAll(' ', '').split('');
        var newVal = '';
        for(i = 0; i< characters.length; i++){
            if(i > 0 && (i % 4) === 0){
                newVal += ' ';
            }
            newVal += characters[i];
        }
        $(this).val(newVal);
    });
    $("#eco_data_iban_b").on('input',function(e) {
        var characters = $(this).val().replaceAll(' ', '').split('');
        var newVal = '';
        for(i = 0; i< characters.length; i++){
            if(i > 0 && (i % 4) === 0){
                newVal += ' ';
            }
            newVal += characters[i];
        }
        $(this).val(newVal);
    });
    $("#checkAll_topay").on('change', function (e) {
        $('[name*="item_to_paid"]').prop('checked', $(this).prop('checked'));
    });
    $("#checkAll_antragArchive").on('change', function (e) {
        $('[name*="restore_request"]').prop('checked', $(this).prop('checked'));
        $('[name*="archive_request"]').prop('checked', $(this).prop('checked'));
    });

    $('input[type=number]').on('mousewheel', function(e) {
        $(e.target).blur();
    });
    $('input[type=number]').on('wheel', function(e) {
        $(e.target).blur();
    });
});
